import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landingPage",
    component: () => import("../views/landingPage.vue")
  },
  {
    path: "/company-register",
    name: "company-register",
    component: () => import("../views/company-register.vue")
  },
  {
    path: "/professional-register",
    name: "professional-register",
    component: () => import("../views/register.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue")
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import("../views/explore.vue")
  },
  {
    path: "/register",
    name: "account",
    component: () => import("../views/account.vue"),
  },
  {
    path: "/message",
    name: "message",
    component: () => import("../views/message.vue"),
  },
  {
    path: "/message-full",
    name: "message-full",
    component: () => import("../views/message-full.vue")
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () => import("../views/password-recovery.vue")
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () => import("../views/reset-password.vue")
  },
  {
    path: "/transaction/confirm",
    name: "Transaction",
    component: () => import("../views/transaction.vue")
  },
  {
    path: "/:companySlug/company-profile",
    name: "companyProfileDetail",
    component: () => import("../views/profileDetailCompany.vue")
  },
  {
    path: "/:userSlug/book-mentorship/:sessionSlug",
    name: "bookMentorship",
    component: () => import("../views/bookMentorship.vue")
  },
  {
    path: "/:userSlug/book-work",
    name: "booking",
    component: () => import("../views/bookWork.vue")
  },
  {
    path: "/:companySlug/company-profile-edit",
    name: "companyEditProfile",
    component: () => import("../views/company-profile-edit.vue"),
  },
  {
    path: "/:userSlug",
    name: "profileDetail",
    component: () => import("../views/profileDetail.vue")
  },
  {
    path: "/:userSlug/profile-edit",
    name: "profileEditstate",
    component: () => import("../views/profileEditstate.vue"),
    meta: {
      requiresAuth: true
    }
  },

]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem("book_me-user-token");
    if (token === null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
