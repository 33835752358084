import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
// import Vuelidate from "vuelidate";
import VCalendar from "v-calendar";
import VueCompositionAPI from '@vue/composition-api'

import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "./plugins/bootstrap-vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.use(VueCompositionAPI)

// Vue.use(Vuelidate);
Vue.use(VCalendar);

Vue.config.productionTip = false;
Vue.use(VueToast, {
  position: "top-right"
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
