import axios from "axios";
import { BASE_URL } from "../../../utils/constants";

const state = {
	company: null
};

const getters = {
	company: state => state.company
};

const mutations = {
	setCompany: (state, company) => {
		state.company = company;
	}
};

const actions = {
	async handleUpdateCompany(_, payload) {
		try {
			const token = localStorage.getItem("book_me-user-token");
			const {
				slug,
				country,
				state,
				name,
				awards,
				size,
				logo,
				about,
				expertise,
				instagram_url,
				facebook_url,
				twitter_url,
				linkedin_url,
				website_url
			} = payload;

			const formData = new FormData();

			formData.append("name", name);
			formData.append("country", country);
			formData.append("state", state);
			formData.append("about", about);
			formData.append("size", size);
			formData.append("expertise", expertise)
			formData.append("awards", awards);
			formData.append("logo", logo);
			formData.append("instagram_url", instagram_url);
			formData.append("facebook_url", facebook_url);
			formData.append("twitter_url", twitter_url);
			formData.append("linkedin_url", linkedin_url);
			formData.append("website_url", website_url);


			const { data } = await axios({
				method: "POST",
				data: formData,
				url: `${BASE_URL}/companies/${slug}`,
				headers: { "Content-Type": "application/json", "Authorization": `${token}` }
			});

			return data;

		} catch (error) {
			throw error.response.data;
		}
	},

	async handleFetchCompany({ commit }, slug) {
		try {
			const token = localStorage.getItem("book_me-user-token");
			const { data: { data } } = await axios({
				method: "GET",
				url: `${BASE_URL}/companies/${slug}`,
				headers: { "Content-Type": "application/json", "Authorization": `${token}` }
			});


			commit('setCompany', data);

			return data;

		} catch (error) {
			throw error.response.data;
		}
	},

	async handleFetchCompanyProfile({ commit }, slug) {
		try {
			const token = localStorage.getItem("book_me-user-token");
			const { data: { data } } = await axios({
				method: "GET",
				url: `${BASE_URL}/companies/${slug}`,
				headers: { "Content-Type": "application/json", "Authorization": `${token}` }
			});
			
			commit("setUser", data);

			return data;

		} catch (error) {
			throw error.response.data;
		}
	},
};

export default {
	state,
	getters,
	mutations,
	actions
};
