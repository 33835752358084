import axios from "axios";

import { BASE_URL } from "../../../utils/constants";

const state = {
  userAbout: null,
  userProfile: null,
  userToken: null,
  user: null
};

const getters = {
  userToken: state => state.user.token,
  user: state => state.user
};

const mutations = {
  setUser: (state, user) => (state.user = user),
  updateUserProfile: (state, profile) =>
    (state.user = { ...state.user, profile }),
  updateUserAbout: (state, about) => (state.user = { ...state.user, ...about })
};

const actions = {
  async registerUser(_, userCredentials) {
    try {
      const {
        password_confirmation,
          password,
          email,
          slug,
          fullName,
          callBackURL,
          type = "talent",
          company_name
      } = userCredentials;

      const formData = new FormData();
      formData.append('email', email);
      formData.append('slug', slug);
      formData.append('callBackURL', callBackURL);
      formData.append('password_confirmation', password_confirmation);
      formData.append('fullName', fullName);
      formData.append('password', password);
      formData.append('type', type);

      if(type === 'company') {
        formData.append('company_name', company_name);
      }

      const { data } = await axios({
        method: "POST",
        data: formData,
        url: `${BASE_URL}/authentication/register` 
      });

      return data;
    } catch (error) {
      if (!error.response.data.errors) {
        throw { errors: [`${error.response.data.message}`] };
      }
      throw error.response.data;
    }
  },

  async verifyUser(_, key) {
    try {
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/authentication/verify?key=${key}`
      });

      return data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async loginUser({ commit }, userCredential) {
    try {
      const { data: result } = await axios({
        method: "POST",
        url: `${BASE_URL}/authentication/login`,
        data: userCredential
      });
      
      const { data } = result;
      console.log("setUser", data);

      commit("setUser", data);

      return result;
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendRecoveryLink(_,{ email, callBackURL}) {
    try {
      const {data} = await axios({
        url: `${BASE_URL}/authentication/get-password-link`,
        method: 'POST',
        data: { email, callBackURL }
      });

      return data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async resetPassword(_,{ password, password_confirmation, key}) {
    try {
      const {data} = await axios({
        url: `${BASE_URL}/authentication/reset-password`,
        method: 'POST',
        data: { password, password_confirmation, key }
      });

      return data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async setLoggedInUser({ commit }, user) {
    commit("setUser", user);
  },

  async logoutUser({ commit }) {
    localStorage.removeItem("book_me-user-token");
    localStorage.removeItem("book_me-user-about");
    localStorage.removeItem("book_me-mentorshipSession");
    localStorage.removeItem("book_me-logged-in-user-slug");
    localStorage.removeItem("user_profile_url");
    localStorage.removeItem("book_me-logged-in-user-type");
    localStorage.removeItem("book_me-logged-in-real-user-slug");
    localStorage.removeItem("book_me-logged-in-user");
    commit("setUser", null);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
