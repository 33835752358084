import axios from "axios";
import { BASE_URL } from "../../../utils/constants";

const state = {
  professionals: null,
  userProfile: null
};

const getters = {
  professionals: state => state.professionals,
  userProfile: state => state.userProfile
};

const mutations = {
  setProfessionals: (state, professionals) =>
    (state.professionals = professionals),
  setUserProfile: (state, profile) =>
    (state.userProfile = profile), 
};

const actions = {
  async updateUserProfile({ commit }, { payload }) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "POST",
        url: `${BASE_URL}/users/update`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`
        }
      });

      commit("updateUserAbout", { ...payload });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateUserBio({ commit, rootState }, payload) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "POST",
        url: `${BASE_URL}/users/update-bio`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`
        }
      });

      commit("setUser", { ...rootState.authentication.user, profile: data.data });
      return data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async fetchSingleUser({ commit }, userSlug) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/users/${userSlug}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`
        }
      });

      data.data.token = {
        token: JSON.parse(localStorage.getItem("book_me-user-token"))
      };

      commit("setUser", data.data);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async fetchSingleUserProfile(_, userSlug) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/users/${userSlug}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`
        }
      });

      data.data.token = {
        token: JSON.parse(localStorage.getItem("book_me-user-token"))
      };

      return data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async fetchUserProfile({ commit }, userSlug) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/users/${userSlug}`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`
        }
      });

      data.data.token = {
        token: JSON.parse(localStorage.getItem("book_me-user-token"))
      };


      commit("setUserProfile", data.data);
      return data;
    } catch (error) {
      throw error.response.data;
    }
  },

   async fetchAllUsers(_) {
    try {
      return await axios({
        method: "GET",
        url: `${BASE_URL}/users/fetch`,
        headers: { "Content-Type": "application/json" }
      });

    } catch (error) {
      throw error.response.data;
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
