<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "jquery/src/jquery.js";
import "slick-carousel/slick/slick.js";
import "../src/assets/libraries/bootstrap/js/bootstrap.min.js";
import "../src/assets/js/select2.min.js";


export default {
  components: {}
};
</script>

<style lang="scss">
// WEBFONT
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@import url("https://fonts.gstatic.com");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800;900&display=swap");

// ICON

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://use.fontawesome.com/releases/v5.0.13/css/all.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.min.css");

@import "../src/assets/libraries/bootstrap/css/bootstrap.min.css";

@import "../src/assets/css/animation";
@import "../src/assets/css/main.css";
@import "../src/assets/css/index.css";

</style>
