import axios from "axios";
import { BASE_URL } from "../../../utils/constants";

const state = {
  mentorshipSessions: [],
  selectedMentorshipSession: null
};

const getters = {
  mentorshipSessions: state => state.mentorshipSessions,
  selectedMentorshipSession: state => state.selectedMentorshipSession
};

const mutations = {
  setMentorshipSessions: (state, sessions) =>
    (state.mentorshipSessions = [...sessions]),
  addSession: (state, session) =>
    (state.mentorshipSessions = [session, ...state.mentorshipSessions]),
  setSelectedMentorshipSession: (state, session) =>
    (state.selectedMentorshipSession = session),
  removeSession: (state, slug) => {
    const index = state.mentorshipSessions.findIndex(session => session.slug === slug);
    if(index !== -1) state.mentorshipSessions.splice(index, 1);
  }
};

const actions = {
  async createSession({ commit }, payload) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "POST",
        url: `${BASE_URL}/mentorships/create`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        }
      });

      commit("addSession", data.data);
      return data;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  },
  async fetchSessions({ commit }, userSlug) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/mentorships/${userSlug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        }
      });

      commit("setMentorshipSessions", data.data);
      return data;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  },
  async fetchASession({ commit }, { userSlug, sessionSlug }) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: "GET",
        url: `${BASE_URL}/mentorships/${userSlug}/session/${sessionSlug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        }
      });

      commit("setSelectedMentorshipSession", data.data);
      return data;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  },
  async bookSession(_, { schedule_id, mentorship_id, time, name, email, slug }) {
    try {
      const { data } = await axios({
        method: "POST",
        data: { schedule_id, mentorship_id, time, name, email },
        url: `${BASE_URL}/bookings/book/${slug}`,
        headers: { "Content-Type": "application/json" }
      });

      return data;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  },

  async bookForWork(_, { fullName, email, title, description, type, amount, phone, duration, slug }) {
    try {
      const { data } = await axios({
        method: "POST",
        data: { fullName, email, title, description, type, amount, phone, duration },
        url: `${BASE_URL}/bookings/work/${slug}`,
        headers: { "Content-Type": "application/json" }
      });

      return data;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  },

  async updateMentorshipSession(_, payload) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data: {message, data } } = await axios({
        method: 'POST',
        url: `${BASE_URL}/mentorships/update-mentorship`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        }
      });

      return message;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  },

   async updateMentorshipSchedule(_, payload) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data: {message, data } } = await axios({
        method: 'POST',
        url: `${BASE_URL}/mentorships/update-schedule`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        }
      });

      return message;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  },

   async confirmBooking(_, reference) {
      try {
        const token = localStorage.getItem("book_me-user-token");
        const { data } = await axios({
        method: 'GET',
        url: `${BASE_URL}/bookings/confirm?reference=${reference}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        }
      });

      return data;

      } catch (error) {
        throw error.response.data;
      }
   },

  async deleteSession({ commit }, sessionSlug) {
    try {
      const token = localStorage.getItem("book_me-user-token");
      const { data } = await axios({
        method: 'DELETE',
        url: `${BASE_URL}/mentorships/${sessionSlug}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`
        }
      });

      commit('removeSession', sessionSlug);
      return data;
    } catch (error) {
      if (error.message.includes("timeout")) {
        throw { errors: ["Please check you network connectivity!"] };
      }
      throw error.response.data;
    }
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
