import axios from "axios";
import { BASE_URL } from "../../../utils/constants";

const state = {};

const getters = {};

const mutations = {};

const actions = {
	async handleSearch(_, searchParams) {
		try {
			const {data : { data }} = await axios({
				method: "GET",
				url: `${BASE_URL}/search?${searchParams}`,
				headers: { "Content-Type": "application/json" }
			});

			return data;
			
		} catch (error) {
			throw error.response.data;
		}
	}
};

export default {
  state,
  getters,
  mutations,
  actions
};
