import Vue from "vue";
import Vuex from "vuex";

import authentication from "./modules/authentication";
import user from "./modules/user";
import mentorshipSession from "./modules/session";
import search from "./modules/search";
import company from "./modules/company";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    authentication,
    user,
    mentorshipSession,
    search,
    company
  }
});
